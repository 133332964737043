<template>
  <div class="edit-device-remind-contaner">
      <div class="form-box">
        <el-form
          label-width="115px"
          :model="ruleForm"
          :status-icon="false"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <!-- 维保记录名称：: -->
          <el-form-item label="维保记录名称:" required prop="recordName">
            <el-input
              type="text"
              maxlength="20"
              v-model="ruleForm.recordName"
              placeholder="请输入维保记录名程"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 维保记录名称：:结束 -->
          <!-- 预计提醒时间：: -->
          <el-form-item label="预计提醒时间:" required prop="time">
            <el-input
              type="date"
              maxlength="20"
              v-model="ruleForm.time"
              placeholder="请选择维保时间"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 预计提醒时间：:结束 -->
          <!-- 维保内容：: -->
          <el-form-item class="mb20" label="维保内容:" required prop="content">
            <el-input
              type="textarea"
              v-model="ruleForm.content"
              maxlength="300"
              placeholder="请输入维保内容"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 维保内容：:结束 -->
          <!-- 开关 start -->
          <el-form-item class="mb20 set" required prop="set">
            <span class="f14 c6">是否接受推送通知：</span>
            <el-switch v-model="ruleForm.set" active-color="#4DE877">
            </el-switch>
          </el-form-item>
          <!-- 开关结束 -->
          <!-- 提交开始 -->
          <el-form-item>
            <el-button class="btn cf f16" @click="submitForm('ruleForm')"
              >保存</el-button
            >
            <el-button @click="$router.go(-1)">取消</el-button>
          </el-form-item>
          <!-- 提交结束 -->
        </el-form>
      </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
let flag = true;
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  name: "YoufujichuangAddRresentTime",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
  },
  data() {
    var validateRecordName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入维保记录名程"));
      }
      callback();
    };
    var validateTime = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择维保时间"));
      }
      callback();
    };

    var validateContent = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入维保内容"));
      }
      callback();
    };
    return {
      id: "",
      ruleForm: {
        recordName: "", //维保记录名称
        time: "", //时间
        content: "", //维保内容
        set: false, //开关
      },
      rules: {
        recordName: [
          { validator: validateRecordName, trigger: ["blur", "change"] },
        ],
        time: [{ validator: validateTime, trigger: ["blur", "change"] }],
        content: [{ validator: validateContent, trigger: ["blur", "change"] }],
      },
      eid: "", //编辑id
      id: "", //设备id
    };
  },

  mounted() {},

  created() {
    this.eid = this.$route.query.eid;
    this.id = this.$route.query.id;
    // 维保提醒-通过id查询
    this.getRepairRemindId();
    this.getArea();
  },

  methods: {
    // 查询省市区
    getArea() {
      this.$API
        .getArea()
        .then((res) => {
          if (res.code == 1) {
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 维保提醒-通过id查询
    getRepairRemindId() {
      let paramData = {
        id: this.eid,
      };
      this.$API
        .getRepairRemindId(paramData)
        .then((res) => {
          if (res.code == 1) {
            let set = res.data.whetherPush == 1 ? true : false; //1表示推送
            this.ruleForm.recordName = res.data.maintenanceName;
            this.ruleForm.time = res.data.remindTime.split(" ")[0];
            this.ruleForm.content = res.data.maintenanceContent;
            this.ruleForm.set = set;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 返回
    toDevice() {
      this.$router.back();
    },
    // 提交
    submitForm(formName) {
      if (!flag) {
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          flag = false;
          let set = this.ruleForm.set == true ? 1 : 0; //1:推送    0：不推送
          let paramData = {
            maintenanceName: this.ruleForm.recordName,
            remindTime: this.ruleForm.time,
            maintenanceContent: this.ruleForm.content,
            whetherPush: set,
            deviceId: Number(this.id),
            id: Number(this.eid),
          };
          //   console.log(paramData, "paramdata");
          this.$API
            .repairRemindEdit(paramData)
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                  duration: 600,
                });
                setTimeout(() => {
                  flag = true;
                  this.$router.push("/repairremind?id=" + this.id);
                }, 800);
              } else {
                flag = true;
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              flag = true;
              //   console.log(err);
            });

          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.edit-device-remind-contaner {
  width: 100%;
  .right {
    button {
      width: 96px;
      height: 40px;
      background: #efefef;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  //   表单
  .form-box {
    margin-top: 20px;
    margin-left: 40px;
    //input表单样式
    .el-input__inner {
      width: 240px;
      height: 40px;
    }
    //input下边距
    .el-form-item {
      padding-bottom: 20px;
    }
    //label样式
    .el-form-item__label {
      text-align: left;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 400px;
      height: 120px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    //按钮样式
    .btn {
      background: #ed1b23;
      color: #ffffff;
      font-size: 14px;
    }
    .set {
      margin-left: -113px !important;
    }
  }
}
</style>